import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { getDeliveryTimeslots, cartSetDeliveryMethod, validateCart } from '../../scripts/fetchApi'

import classes from './AdvanceDays.module.css'
import { variousActions } from "../../store/various"
import { setSessionStorageItem, setStorageItem } from "../../scripts/localStorage"
import { cartActions } from "../../store/cart"
import { locationActions } from "../../store/location"
import PenIcon from "../../icons/penicon"
import { compareEarliestReadyTimeWithTimeSlot, formatDate, formatOLODate } from "../../scripts/helpers"

const AdvanceDays = ({closeModal}) => {
    const dispatch = useDispatch()

    const [loader, setLoader] = useState(false)
    const [showContinueButton, setShowContinueButton] = useState(false)
    const [days, setDays] = useState(null)
    const [timeSlots, setTimeSlots] = useState(null)
    const cart = useSelector(state => state.cart.cart)
    const location = useSelector(state => state.location.location) 
    const [timeSlotsLoading, setTimeSlotsloading] = useState(false)
    const dayRef = useRef()
    const timeRef = useRef()

    useEffect(() => {
        const dates = []
        let limitDay = null
        if (cart && cart.earliestreadytime) {
            const today = new Date()
            if (new Date(cart.earliestreadytime) > today) {
                limitDay = formatOLODate(cart.earliestreadytime)
            } else {
                limitDay = today
            }
        }  

        for (let index = 0; index < location.services.advance_days; index++) {
            const today = new Date(limitDay);
            const day = today.setDate(today.getDate() + index)
            dates.push({
                weekDay: new Date(day).toLocaleDateString('en-US', {
                    weekday: 'long',
                }),
                day: new Date(day).toLocaleDateString('en-US', {
                    day: 'numeric'
                }),
                // date: (new Date(day)).toISOString().split('T')[0],
                date: formatDate(day),
                dateUS: new Date(day).toLocaleDateString('en-US'),
                fancyFormat: new Date(day).toLocaleDateString('en-US', {
                    month: 'short',
                    day: '2-digit',
                    year: 'numeric'
                }),
            })     
        }
        setDays(dates)

    }, [location])


    const validateCartHandler = async (cartId, orderInfo) => {

        const validCart = await validateCart(cartId)

        if (validCart.id) {
            // setStorageItem('currentCart', validCart)
            setSessionStorageItem('currentCart', validCart)
            dispatch(cartActions.addCart(validCart))
            dispatch(locationActions.setOrderType(orderInfo.time))
            dispatch(locationActions.setOrderDate(orderInfo.schedule_date))
            dispatch(locationActions.setOrderTime(orderInfo.schedule_time))
            
            closeModal()
        } else {
            dispatch(variousActions.setError(validCart))
        }
        setLoader(false)
    }



    const changeTimeHandler = async (event) => {
        setTimeSlotsloading(true)
        setTimeSlots(null)

        const todaysDate = new Date()
        const selectedDate = new Date(event.target.value) 
        const isToday = todaysDate.toDateString() === selectedDate.toDateString()
        const timeSlots = await getDeliveryTimeslots(cart.id, location.id, event.target.value)

        let slotsToRemove = 0
        if (cart && cart.leadtimeestimateminutes) {            
            slotsToRemove = Math.ceil(cart.leadtimeestimateminutes/15)
            if (slotsToRemove > 16) {
                slotsToRemove = 0
            }
        }

        if (timeSlots.status === 200) {          
            let timeSlotsArr = []
            for (const [key, value] of Object.entries(timeSlots.data)) {               
                    timeSlotsArr.push({
                    label: key,
                    value: value
                })
            }

            let filteredTimeSlots = timeSlotsArr
            // console.log('cart.id,:::: ', cart.id)
            // console.log('filteredTimeSlots:::: ', filteredTimeSlots)
            if (isToday) {
                console.log('today!')

                let newSlotsToRemove = compareEarliestReadyTimeWithTimeSlot(cart.earliestreadytime, timeSlotsArr, event.target.value)

                console.log('newSlotsToRemove:::: ', newSlotsToRemove)
                // filteredTimeSlots = filterFutureTimesForToday(timeSlotsArr, event.target.value)
                // filteredTimeSlots.splice(0, slotsToRemove)
                filteredTimeSlots.splice(0, newSlotsToRemove)
            } 
            // timeSlotsArr.splice(0, slotsToRemove);

            // setTimeSlots(timeSlotsArr)
            setTimeSlots(filteredTimeSlots)
            
        } else {
            dispatch(variousActions.setError(timeSlots))
            
        }
        setTimeSlotsloading(false)
    }

    const selectTimeHandler = () => {
        setShowContinueButton(true)
    }

    const setScheduledDeliveryHandler = async () => {
        setLoader(true)       

        const newDateCartInfo = {            
            delivery_mode: cart.deliverymode === 'dispatch' ? 'delivery' : cart.deliverymode,
            time: 'schedule',
            schedule_date: dayRef.current.value,
            schedule_time: timeRef.current.value
        }

        if (cart.deliverymode === 'dispatch') {
            newDateCartInfo.zip_code = cart.deliveryaddress.zipcode
            newDateCartInfo.street_address = cart.deliveryaddress.streetaddress
            newDateCartInfo.city = cart.deliveryaddress.city
            newDateCartInfo.phone_number = cart.deliveryaddress.phonenumber
            newDateCartInfo.special_instructions = cart.deliveryaddress.specialinstructions
            newDateCartInfo.building = cart.deliveryaddress.building
        }

        const deliveryCart = await cartSetDeliveryMethod(cart.id, newDateCartInfo)

        if (deliveryCart.id) {
            validateCartHandler(deliveryCart.id, newDateCartInfo)
        } else {
            dispatch(variousActions.setError(deliveryCart))
            setLoader(false)
        }
    }

    return <>
        <div className={classes.selects_container}>
            <div className={classes.time_slots_container}>
                {days && <select ref={dayRef} onChange={changeTimeHandler} className={`select ${classes.select}`} defaultValue={'Date'}>
                    <option disabled value="Date" hidden>Date</option>
                    {days.map(item => (                
                        <option key={item.day + item.date} value={item.date} >
                            {/* {item.weekDay} {item.day} */}
                            {item.fancyFormat}
                        </option>
                    ))}    
                </select>}
                <span className={classes.open_drawer}>
                    <PenIcon />
                </span>
            </div>
            <div className={`${classes.time_slots_container} ${!timeSlots ? classes.disabled : ''}`}>
                <select ref={timeRef} className={`select ${classes.select}`} onChange={selectTimeHandler} disabled={!timeSlots} defaultValue={'Time'}>
                    <option disabled value="Time" hidden>Time</option>
                    {timeSlots && !timeSlotsLoading && <>
                        {timeSlots.map(item => (
                            <option key={item.value} value={item.value}>{item.label}</option>
                        ))} 
                    </>}               
                </select>
                <span className={classes.open_drawer}>
                    <PenIcon fill="#fff"/>
                </span>
                {!timeSlots && timeSlotsLoading && <div className={classes.loading_overlay}><span className="loader dark"></span></div>}
            </div>
            {/* {!timeSlots && timeSlotsLoading && <div><span className="loader dark"></span></div>} */}
            {/* {timeSlots && !timeSlotsLoading &&<select ref={timeRef} className={`select ${classes.select}`} onChange={selectTimeHandler}>
                <option disabled selected hidden>Select Time</option>
                {timeSlots.map(item => (
                    <option key={item.value} value={item.value}>{item.label}</option>
                ))}    
            </select>}  */}
            
        </div>
        {showContinueButton && <div className={classes.controls}>
            {!loader && <button onClick={setScheduledDeliveryHandler} className="btn">Confirm</button>}
            {loader && <div className="btn"><span className="loader"></span></div>}
        </div>}
    </>
}

export default AdvanceDays