import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { locationActions } from "../../store/location";

const GoToLocationsDirectory = () => {
    const navigate = useNavigate();
    const dispatch = (useDispatch())

    const goToLocationsHandler = () => {
        
        dispatch(locationActions.forceLocationsSideBarStateClosed()) 
        navigate('/directory')
    }


    return <button onClick={goToLocationsHandler} className="btn-underline">View all locations</button>
}

export default GoToLocationsDirectory