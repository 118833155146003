import { useSelector } from 'react-redux'
import { getItemFromStorage } from '../../../scripts/localStorage'
import classes from './SelectedOptionsContainer.module.css'
import SelectedChoice from './SelectedChoice'
// import PresentationCustomFields from './PresentationCustomFields'
import { useEffect, useState } from 'react'


function getSelectedOptions(arr, finalArr) {

    if (arr) {
        arr.forEach(element => {
            if (element.itemSelected) {
                const index = finalArr.findIndex(object => {
                    return object.id == element.id
                })
                if (index === -1) {
                    finalArr.push({
                        modName: element.description,
                        id: element.id,
                        selectedoptions: element.options?.filter(item => item.itemSelected)
                    })
                }
                
                if (element.options) {
                    element.options.forEach(opt => {                        
                        if (opt.modifiers) {
                            getSelectedOptions(opt.modifiers, finalArr)
                        }
                    })
                }
            } else {
                if (element.options) {
                    element.options.forEach(opt => {
                        if (opt.itemSelected) {
                            const index = finalArr.findIndex(object => {
                                return object.id == element.id
                            })
                            if (index === -1) {
                                finalArr.push({
                                    modName: element.description,
                                    id: element.id,
                                    selectedoptions: element.options?.filter(item => item.itemSelected)
                                })
                            }
                            
                        }
                    })
                }
            }
        })
    }
}

const SelectedOptionsContainer = () => {
    const product = getItemFromStorage('foundProduct')?.product
    // const options = useSelector(state => state.product.product)

    const [selectedOptions, setSelectedOptions] = useState(null)
    const productDetails = useSelector(state => state.productDetails.data)

    useEffect(() => {
        let setOptions = []
        getSelectedOptions(productDetails, setOptions)
        setSelectedOptions(setOptions)
    }, [productDetails])


    return <>
        {product && <div className={classes.create_options_container}>
            <div className={classes.create_options}>
                <h3>
                    {product.name.toLowerCase().includes('ice') && <>Your Ice Cream Cake Choices</>}
                    {!product.name.toLowerCase().includes('ice') && <>Your cookie cake choices:</>}
                    
                </h3>

                <div className={classes.simple_choices}>
                    {selectedOptions && selectedOptions.length > 0 && <>
                        {selectedOptions.map(option => (
                            <SelectedChoice key={option.id} data={option}/>
                        ))}
                    </>}  
                    {/* {options && options.length > 0 && <>
                        {options.filter(item => item.selected).map(option => (
                            <SelectedChoice key={option.choice_id} data={option}/>
                        ))}
                    </>}   */}
                                
                </div>    
                {/* <PresentationCustomFields /> */}
            </div>
            {!product.name.toLowerCase().includes('ice') && <div className={classes.disclaimer}>
                <p>We strive to provide our customers with the best possible experience when ordering our Cookie Cakes. While our custom Cookie Cake design tool offers a representation of the final product, actual designs may vary slightly depending on the specific store location and the individual decorator's artistic interpretation.</p>
                
                <p>Online Cookie Cake orders require a 2-hour minimum lead time. Please keep in mind that some custom designs may be restricted by copyright laws, and we do not produce any cakes that use profanity or vulgar messages. If you need your Cookie Cake earlier or don't see the design you want, please call the store directly. We are happy to help!</p>
            </div>}
        </div>}
    </>
}

export default SelectedOptionsContainer