import classes from './PresentationBoxCreate.module.css'
import { getItemFromStorage } from '../../../scripts/localStorage'
import SelectedChoice from './SelectedChoice'
import { useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from '../../../hooks/hooks'
import ArrowUpIcon from '../../../icons/arrowupicon'
// import { useState } from 'react'
import { productChoicesActions } from '../../../store/productChoices'
// import ConfirmAllSelections from './ConfirmAllSelections'
import EyeIcon from '../../../icons/eyeicon'
import BuilderImage from './BuilderImage'
import PresentationCustomFields from './PresentationCustomFields'
import { useEffect, useState } from 'react'
import BuilderImageNoCanvas from './builder_image/BuilderImageNoCanvas'
// import BuilerImageWrapper from './BuilerImageWrapper'


function getSelectedOptions(arr, finalArr) {
    if (arr) {
        arr.forEach(element => {
            if (element.itemSelected) {
                // console.log('element.itemSelected:::: ', element)
                const index = finalArr.findIndex(object => {
                    return object.id == element.id
                })
                if (index === -1) {
                    finalArr.push({
                        modName: element.description,
                        id: element.id,
                        selectedoptions: element.options?.filter(item => item.itemSelected)
                    })
                }
                
                if (element.options) {
                    element.options.forEach(opt => {                        
                        if (opt.modifiers) {
                            getSelectedOptions(opt.modifiers, finalArr)
                        }
                    })
                }
            } else {
                // console.log('element.itemSelected:::: ', element)
                if (element.options) {
                    element.options.forEach(opt => {
                        if (opt.itemSelected) {
                            const index = finalArr.findIndex(object => {
                                return object.id == element.id
                            })

                            if (index === -1) {
                                finalArr.push({
                                    modName: element.description,
                                    id: element.id,
                                    selectedoptions: element.options?.filter(item => item.itemSelected)
                                })
                            }
                            if (opt.modifiers) {
                                getSelectedOptions(opt.modifiers, finalArr)
                            }
                        }
                    })
                }
            }
        })
    }
}

const PresentationBoxCreate = () => {
    const dispatch = useDispatch()
    const product = getItemFromStorage('foundProduct')?.product
    const [selectedOptions, setSelectedOptions] = useState(null)

    const screen1024andlarger = useMediaQuery('lg')
    const options = useSelector(state => state.product.product)

    const accordionState = useSelector(state => state.product.mobileSelectedChoicesState)

    // const [drawerState, setDrawerState] = useState(false)
    useEffect(() => {
        dispatch(productChoicesActions.confirmOptions(false))
    })
    const titleAccordionHandler = () => {
        // setDrawerState(p => p = !p)
        dispatch(productChoicesActions.setMobileSelectedChoicesState())
        
    }

    const productDetails = useSelector(state => state.productDetails.data)

    useEffect(() => {
        let setOptions = []
        getSelectedOptions(productDetails, setOptions)
        setSelectedOptions(setOptions)
    }, [productDetails])

    return <div className={`${classes.presentation_box} presentation-box-create`}>
        {product && <>
            {screen1024andlarger && <>
                {product.name && product.name !== '' && <h1 className={classes.page_title}>{product.name}</h1>}
            

                <div className={classes.presentation_box_container}>
                    <div className={classes.presentation_box_details}>                    
                        {product.image_large_url && <div className={classes.composite_image}>
                            {/* <div className={classes.composite_image_inner}>
                                <img src={product.image_large_url} alt={product.name}/>
                            </div> */}
                            {!product.name.toLowerCase().includes("cookie") && <div className={classes.composite_image_inner}>
                                <img src={product.image_large_url} alt={product.name}/>
                            </div>}
                            {/* {product.name.toLowerCase().includes("cookie") && <BuilderImage options={options} selectedOptions={selectedOptions}/>} */}
                            {product.name.toLowerCase().includes("cookie") && <BuilderImageNoCanvas options={options} selectedOptions={selectedOptions}/>}

                            
                        </div>}
                    </div>

                    <div className={classes.create_options}>
                        <h3>
                            {product.name.toLowerCase().includes('ice') && <>Your Ice Cream Cake Choices</>}
                            {!product.name.toLowerCase().includes('ice') && <>Your cookie cake choices:</>}
                            
                        </h3>

                        <div className={classes.simple_choices}>
                            {selectedOptions && selectedOptions.length > 0 && <>
                                {selectedOptions.map(option => (
                                    <SelectedChoice key={option.id} data={option}/>
                                ))}
                            </>}  
                                        
                        </div>    
                        <PresentationCustomFields />                 
                    </div>
                </div>
                {!product.name.toLowerCase().includes('ice') && <div className={classes.disclaimer}>
                    <p>We strive to provide our customers with the best possible experience when ordering our Cookie Cakes. While our custom Cookie Cake design tool offers a representation of the final product, actual designs may vary slightly depending on the specific store location and the individual decorator's artistic interpretation.</p>
                    
                    <p>Online Cookie Cake orders require a 2-hour minimum lead time. Please keep in mind that some custom designs may be restricted by copyright laws, and we do not produce any cakes that use profanity or vulgar messages. If you need your Cookie Cake earlier or don't see the design you want, please call the store directly. We are happy to help!</p>
                </div>}
            </>}
            
            {!screen1024andlarger && <>
                {product.name && product.name !== '' && <h1 className={`${classes.page_title_button} ${accordionState ? classes.drawer_button_opened : ''}`}>
                    <button onClick={titleAccordionHandler}>
                        {/* {product.name} */}
                        {!accordionState && <span className={classes.drawer_button_opened_text}><EyeIcon /> Show Cookie Cake Design</span>}
                        {accordionState && <span>Your Cookie Cake</span>}
                        <span className={classes.page_title_icon}>
                            <ArrowUpIcon />
                        </span>
                    </button>    
                </h1>}
            

                <div className={`${classes.presentation_box_drawer} ${accordionState ? classes.drawer_opened : ''}`}>
                    <div className={classes.presentation_box_container}>
                        <div className={classes.presentation_box_details}>
                            {product.description && product.description !== '' && <div className={classes.description}>{product.description}</div>}
                            
                            {product.image_url && <div className={classes.composite_image}>
                                {/* <div className={classes.composite_image_inner}>
                                    <img src={product.image_url} alt={product.name}/>
                                </div> */}
                                {!product.name.toLowerCase().includes("cookie") && <div className={classes.composite_image_inner}>
                                    <img src={product.image_large_url} alt={product.name}/>
                                </div>}
                                {/* {product.name.toLowerCase().includes("cookie") && <BuilderImage options={options} mobile={accordionState}  selectedOptions={selectedOptions}/>} */}
                                {product.name.toLowerCase().includes("cookie") && <BuilderImageNoCanvas options={options} mobile={accordionState} selectedOptions={selectedOptions}/>}

                            </div>}
                        </div>

                        <div className={classes.create_options}>

                            <div className={classes.simple_choices}>
                                {selectedOptions && selectedOptions.length > 0 && <>
                                    {selectedOptions.map(option => (
                                        <SelectedChoice key={option.id} data={option}/>
                                    ))}
                                </>} 

                            </div>
                        
                        </div>                        
                    </div>
                    {!product.name.toLowerCase().includes('ice') && <div className={classes.disclaimer}>
                        <p>We strive to provide our customers with the best possible experience when ordering our Cookie Cakes. While our custom Cookie Cake design tool offers a representation of the final product, actual designs may vary slightly depending on the specific store location and the individual decorator's artistic interpretation.</p>
                        
                        <p>Online Cookie Cake orders require a 2-hour minimum lead time. Please keep in mind that some custom designs may be restricted by copyright laws, and we do not produce any cakes that use profanity or vulgar messages. If you need your Cookie Cake earlier or don't see the design you want, please call the store directly. We are happy to help!</p>
                    </div>}
                </div>
            </>}

            
        </>}
    </div>
}

export default PresentationBoxCreate